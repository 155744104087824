import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { OpenWishTablePage } from "../components/openWishes/OpenWishTablePage";
import { HeadCell } from "../components/common/table/TableHead";
import { OrderDirection } from "../utils/order";

const headCellsForAllStores: HeadCell[] = [
  { id: "created", numeric: true, sortable: true, disablePadding: false, label: "PVM" },
  { id: "store.name", numeric: false, sortable: true, disablePadding: false, label: "Myymälä" },
  { id: "openWish.wishText", numeric: false, sortable: false, disablePadding: false, label: "Toive" },
  { id: "openWish.country", numeric: false, sortable: false, disablePadding: false, label: "Maa" },
  { id: "openWish.productGroupIds", numeric: false, sortable: false, disablePadding: false, label: "Tuoteryhmä" },
  { id: "status", numeric: false, sortable: false, disablePadding: false, label: "Tila" },
  { id: "comment", numeric: false, sortable: false, disablePadding: false, label: "Kommentti" },
];
const headCellsForSingleStore: HeadCell[] = [
  { id: "created", numeric: true, sortable: true, disablePadding: false, label: "PVM" },
  { id: "openWish.wishText", numeric: false, sortable: false, disablePadding: false, label: "Toive" },
  { id: "openWish.country", numeric: false, sortable: false, disablePadding: false, label: "Maa" },
  { id: "openWish.productGroupIds", numeric: false, sortable: false, disablePadding: false, label: "Tuoteryhmä" },
  { id: "status", numeric: false, sortable: false, disablePadding: false, label: "Tila" },
  { id: "comment", numeric: false, sortable: false, disablePadding: false, label: "Kommentti" },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
  }),
);

export const ListOpenWishes = ({ loggedUserName }: { loggedUserName?: string }): JSX.Element => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderDirection, setOrderDirection] = useState<OrderDirection>("desc");
  const [orderBy, setOrderBy] = useState<string>("created");

  const [editRow, setEditRow] = useState("");

  const { storeId, wishFilters } = useLocalStorage();

  const storeIdFilter = storeId || undefined;
  const createdDateFilterStart = wishFilters?.createdStart || null;
  const createdDateFilterEnd = wishFilters?.createdEnd || null;
  const statusFilter = wishFilters?.status?.map((s) => s.value);

  const queryFilters = {
    "created.start": createdDateFilterStart,
    "created.end": createdDateFilterEnd,
    "store.id": storeIdFilter,
    status: statusFilter,
  };
  // Show store column only when store is not selected
  const headCells = storeIdFilter ? headCellsForSingleStore : headCellsForAllStores;

  const statusFilterDependency = JSON.stringify(statusFilter);
  useEffect(() => {
    // reset page if filters change
    setPage(0);
  }, [createdDateFilterStart, createdDateFilterEnd, storeIdFilter, statusFilterDependency]);

  return (
    <div className={classes.root}>
      <OpenWishTablePage
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        orderDirection={orderDirection}
        setOrderDirection={setOrderDirection}
        filters={queryFilters}
        headCells={headCells}
        editRow={editRow}
        setEditRow={setEditRow}
        loggedUserName={loggedUserName}
      />
      <OpenWishTablePage
        page={page + 1}
        rowsPerPage={rowsPerPage}
        orderBy={orderBy}
        orderDirection={orderDirection}
        filters={queryFilters}
        headCells={headCells}
        loggedUserName={loggedUserName}
        hidden
      />
    </div>
  );
};
