import { ApiResponse } from "../utils/response";
import { getFromApi, putToApi, Query } from "../utils/request";

export enum AgentStatus {
  ACTIVE = "ACTIVE",
  WARNING = "WARNING",
  BLOCKED = "BLOCKED",
}

export interface AgentStatusOption {
  name: string;
  value: AgentStatus;
}

export interface AgentFilter {
  agentId?: string;
  status?: string;
}

export const statuses: AgentStatusOption[] = [
  { value: AgentStatus.ACTIVE, name: "Aktiivinen" },
  { value: AgentStatus.WARNING, name: "Varoitus" },
  { value: AgentStatus.BLOCKED, name: "Estetty" },
];

export interface Agent {
  agentId: string;
  name: string;
  status: AgentStatus;
  comment: string | null;
  created: Date;
  updated: Date;
  updatedBy: string;
}

export interface FindAgentsParams extends Query {
  page: number;
  pageSize: number;
  orderBy?: string;
  orderDirection?: string;
  filters?: AgentFilter;
}

export const getAllAgents = async (): Promise<ApiResponse<Agent[]>> => {
  return await getFromApi<Agent[]>("/v1/agents");
};

export const findAgents = async (params: FindAgentsParams): Promise<ApiResponse<Agent[]>> => {
  return await getFromApi<Agent[]>("/v1/agents", params);
};

export interface UpdateAgentParams {
  agentId: string;
  status?: AgentStatus;
  comment?: string;
  updatedBy?: string;
}

export const updateAgents = async (params: UpdateAgentParams[]): Promise<ApiResponse<Agent[]>> => {
  return await putToApi<Agent[]>(`/v1/agents`, params);
};
