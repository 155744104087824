// POST form data by javascript with page transition
// @params payload: object with key value, where form data will be key=value (array is supported)
// @see https://stackoverflow.com/questions/133925/javascript-post-request-like-a-form-submit
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const postFormToUrl = (url?: string, payload?: any): void => {
  if (!url || url.length === 0 || !payload) {
    return;
  }

  const form = document.createElement("form");

  // Move the submit function to another variable
  // so that it doesn't get overwritten.
  form.submit_function = form.submit;

  form.setAttribute("method", "post");
  form.setAttribute("action", url);

  (Object.keys(payload) as string[]).forEach((key) => {
    if (Array.isArray(payload[key])) {
      payload[key].forEach((value: string) => {
        addField(form, `${key}[]`, value);
      });
    } else {
      addField(form, key, payload[key]);
    }
  });

  document.body.appendChild(form);
  form.submit_function(); // Call the renamed function.
};

const addField = (form: HTMLFormElement, key: string, value: string) => {
  const hiddenField = document.createElement("input");
  hiddenField.setAttribute("type", "hidden");
  hiddenField.setAttribute("name", key);
  hiddenField.setAttribute("value", value);

  form.appendChild(hiddenField);
};
