import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import format from "date-fns/format";
import { fi } from "date-fns/locale";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import useSWR from "swr";
import { Select } from "../common/Select";
import { HeadCell, TableHead } from "../common/table/TableHead";
import fetch from "../../utils/fetch";
import { OrderDirection } from "../../utils/order";
import { getApiUrl } from "../../utils/request";
import { ApiResponse } from "../../utils/response";
import {
  statuses,
  WishStatus,
  WishStatusOption,
  updateWishes,
  UpdateWishParams,
  OpenWish,
  updateOpenWishes,
} from "../../api/wish";
import { TableToolbar } from "../common/table/TableToolbar";
import { isArray } from "lodash";
import { WishStatusValue } from "../common/WishStatusValue";
import { MultiRowActions } from "./MultiRowActions";
import { OpenWishFilterToolbar } from "./OpenWishFilterToolbar";
import { getCountries } from "../../utils/countries/countries";
import { productGroupsForOpenWish } from "../../api/productGroup";

interface EditRowInput {
  wishId: string;
  status: string;
  comment: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    tableRow: {
      verticalAlign: "text-top",
    },
    comment: {
      display: "-webkit-box",
      "-webkit-line-clamp": 3,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
    },
    createdDate: {
      "&:hover": {
        textDecoration: "underline",
        textDecorationStyle: "dotted",
        textDecorationColor: "#aaa",
      },
    },
  }),
);

interface Props {
  page: number;
  rowsPerPage: number;
  orderBy: string;
  setOrderBy?: (orderBy: string) => void;
  orderDirection: OrderDirection;
  setOrderDirection?: (orderDirection: OrderDirection) => void;
  filters: Record<string, unknown>;
  headCells: HeadCell[];
  hidden?: boolean;
  editRow?: string;
  setEditRow?: (id: string) => void;
  setPage?: (page: number) => void;
  setRowsPerPage?: (rowsPerPage: number) => void;
  loggedUserName?: string;
}
export const OpenWishTablePage = ({
  page,
  rowsPerPage,
  orderBy,
  setOrderBy = () => null,
  orderDirection,
  setOrderDirection = () => null,
  filters,
  headCells,
  hidden = false,
  editRow = "",
  setEditRow = () => null,
  setPage = () => null,
  setRowsPerPage = () => null,
  loggedUserName,
}: Props): JSX.Element => {
  const classes = useStyles();

  const { data, error: fetchError, mutate } = useSWR<ApiResponse<OpenWish[]>>(
    getApiUrl("/v1/openwishes", { page, pageSize: rowsPerPage, orderBy, orderDirection, filters }),
    fetch,
  );
  const wishes = data?.data || [];
  const count = data?.count || 1;
  const error = data?.error;
  const loading = !error && !fetchError && !data;
  const { handleSubmit, register, control } = useForm<EditRowInput>();
  const countries = getCountries();

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = wishes.map((w) => w.id);
      handleRowSelect(newSelecteds);
      return;
    }
    handleRowSelect([]);
  };

  const handleUpdateSingle = async (values: EditRowInput) => {
    const data: UpdateWishParams = {
      wishId: values.wishId,
      status: statuses.find((s: WishStatusOption) => s.value === values.status)?.value || WishStatus.NEW,
      comment: values.comment,
      updatedBy: loggedUserName,
    };

    mutate(
      {
        data: wishes.map((w) => (w.id === values.wishId ? { ...w, ...data } : w)),
        count: wishes.length,
      },
      false,
    );
    setEditRow("");
    await updateWishes([data]);
    mutate();
  };

  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const isSelected = (name: string) => selectedRows.indexOf(name) !== -1;
  const handleRowSelect = (wishId: string | string[]) => {
    if (isArray(wishId)) {
      setSelectedRows(wishId);
    } else {
      const selectedIndex = selectedRows.indexOf(wishId);
      let newSelected: string[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedRows, wishId);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedRows.slice(1));
      } else if (selectedIndex === selectedRows.length - 1) {
        newSelected = newSelected.concat(selectedRows.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selectedRows.slice(0, selectedIndex), selectedRows.slice(selectedIndex + 1));
      }

      setSelectedRows(newSelected);
    }
  };

  const handleUpdateMultiple = (statusForSelectedRows: string) => {
    const status = statuses.find((s: WishStatusOption) => s.value === statusForSelectedRows)?.value || WishStatus.NEW;
    mutate(updateOpenWishes(selectedRows.map((wishId) => ({ wishId, status, updatedBy: loggedUserName }))));
    setSelectedRows([]);
  };
  // Show store column only when store is not selected
  const showStoreColumn = !filters["store.id"];

  if (hidden) return <div style={{ display: "none" }} />;
  return (
    <>
      <TableToolbar
        hasSelections={selectedRows.length > 0}
        actionsComponent={<MultiRowActions selected={selectedRows} handleUpdateMultiple={handleUpdateMultiple} />}
        filtersComponent={<OpenWishFilterToolbar />}
      />
      <Paper className={classes.paper}>
        <TableContainer>
          <form onSubmit={handleSubmit(handleUpdateSingle)}>
            <div style={{ position: "relative" }}>
              {loading && wishes.length > 0 && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(255,255,255,0.7)",
                    zIndex: 1000,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress size={36} />
                </div>
              )}
              <Table className={classes.table} size="small">
                <TableHead
                  headCells={headCells}
                  numSelected={selectedRows.length}
                  order={orderDirection}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={wishes.length}
                  selectAllDisabled={!!editRow}
                />
                <TableBody>
                  {wishes.map((wish: OpenWish, index: number) => {
                    const isItemSelected = isSelected(wish.id);
                    const isEditing = !!editRow && editRow === wish.id;
                    const isStatusEditable = statuses.find((s) => s.value === wish.status)?.userSettable;
                    const country = countries.find((c) => c.value === wish.openWish.country);
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={wish.id}
                        selected={isItemSelected}
                        className={classes.tableRow}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            disabled={!!editRow}
                            inputProps={{ "aria-label": `Valitse toive ${wish.wishNumber}` }}
                            onChange={() => handleRowSelect(wish.id)}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row" align="right">
                          <Tooltip
                            placement="bottom"
                            title={
                              <>
                                Luotu: {format(new Date(wish.created), "Pp", { locale: fi })}
                                <br />
                                {wish.updated && (
                                  <span style={{ whiteSpace: "nowrap" }}>
                                    {`Muokattu: ${format(new Date(wish.updated), "Pp", { locale: fi })} `}
                                    {wish.updatedBy ? `(${wish.updatedBy})` : ""}
                                    <br />
                                  </span>
                                )}
                                Myymälä: {wish.store?.id} {wish.store?.name}
                              </>
                            }
                            enterDelay={700}
                            style={{ maxWidth: 450 }}
                          >
                            <Box className={classes.createdDate} component="span">
                              {format(new Date(wish.created), "P", { locale: fi })}
                            </Box>
                          </Tooltip>
                        </TableCell>
                        {showStoreColumn && (
                          <TableCell>
                            {wish.store?.id} {wish.store?.name}
                          </TableCell>
                        )}
                        <TableCell>{wish.openWish.wishText}</TableCell>
                        <TableCell>
                          {country?.flag ? `${country?.flag}\u00a0` : ""}
                          {country?.name}
                        </TableCell>
                        <TableCell>
                          {productGroupsForOpenWish.find((p) => p.id === wish.openWish.productGroup.id)?.name.fi}
                        </TableCell>
                        <TableCell align="left">
                          {isEditing && isStatusEditable ? (
                            <Controller
                              name="status"
                              control={control}
                              defaultValue={wish.status}
                              rules={{ required: true }}
                              render={(props) => (
                                <Select
                                  id={`statusForWish-${wish.wishNumber}`}
                                  label=""
                                  options={statuses.filter((s) => s.userSettable)}
                                  variant="standard"
                                  size="small"
                                  {...props}
                                />
                              )} // props contains: onChange, onBlur and value
                            />
                          ) : (
                            <WishStatusValue status={statuses.find((s) => s.value === wish.status)} />
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {isEditing ? (
                            <TextField
                              id={`commentForWish-${wish.wishNumber}`}
                              name="comment"
                              defaultValue={wish.comment}
                              variant="standard"
                              inputRef={register}
                              size="small"
                            />
                          ) : (
                            <Tooltip title={wish.comment || ""} enterDelay={700}>
                              <Box className={classes.comment}>{wish.comment}</Box>
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell align="right" padding="checkbox">
                          <Box display="flex" alignContent="center" justifyContent="flex-end">
                            {isEditing ? (
                              <>
                                <input type="hidden" name="wishId" value={wish.id} ref={register} />
                                {
                                  <>
                                    <Tooltip title="Tallenna" enterDelay={700}>
                                      <span>
                                        <IconButton
                                          type="submit"
                                          value={wish.id}
                                          disabled={!!editRow && editRow !== wish.id}
                                          size="small"
                                        >
                                          <CheckCircleOutlineIcon fontSize="default" />
                                        </IconButton>
                                      </span>
                                    </Tooltip>
                                    <Tooltip title="Peruuta" enterDelay={700}>
                                      <span>
                                        <IconButton
                                          disabled={!!editRow && editRow !== wish.id}
                                          size="small"
                                          onClick={() => setEditRow("")}
                                        >
                                          <CloseIcon fontSize="default" />
                                        </IconButton>
                                      </span>
                                    </Tooltip>
                                  </>
                                }
                              </>
                            ) : (
                              <Tooltip title="Muokkaa" enterDelay={700}>
                                <span>
                                  <IconButton
                                    disabled={selectedRows.length > 0 || (!!editRow && editRow !== wish.id)}
                                    size="small"
                                    onClick={() => setEditRow(wish.id)}
                                  >
                                    <EditIcon fontSize="default" />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {wishes.length === 0 && (
                    <TableRow style={{ height: 55 }}>
                      <TableCell colSpan={headCells.length + 2} align="center" valign="middle">
                        {loading ? <CircularProgress /> : "Ei tuloksia"}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </form>
        </TableContainer>
        {wishes.length > 0 && (
          <TablePagination
            rowsPerPageOptions={[10, 20, 40]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangeRowsPerPage={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              setRowsPerPage(parseInt(event.target.value));
              setPage(0);
            }}
            onChangePage={(event: unknown, newPage: number) => setPage(newPage)}
          />
        )}
      </Paper>
    </>
  );
};
