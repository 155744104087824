import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import format from "date-fns/format";
import { fi } from "date-fns/locale";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import theme from "./theme";
import { Provider as ReduxProvider } from "react-redux";
import reduxStore from "./reduxStore";
import "./index.css";
import { LocalStorageProvider } from "./hooks/useLocalStorage";
import { authProvider } from "./authProvider";
import { AuthenticationResult, EventMessage, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

const pca = authProvider;

class FiLocalizedUtils extends DateFnsUtils {
  getCalendarHeaderText(date: Date) {
    return format(date, "LLLL", { locale: this.locale });
  }

  getDatePickerHeaderText(date: Date) {
    return format(date, "d. MMMM", { locale: this.locale });
  }
}

pca.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    pca.setActiveAccount(account);
  }
});

ReactDOM.render(
  <ReduxProvider store={reduxStore}>
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={FiLocalizedUtils} locale={fi}>
        <LocalStorageProvider>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <MsalProvider instance={pca}>
            <App />
          </MsalProvider>
        </LocalStorageProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  </ReduxProvider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
