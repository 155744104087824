import { IPublicClientApplication } from "@azure/msal-browser";
import fetch from "isomorphic-unfetch";
import { getApiHeaders, getAuthHeaders } from "./request";
import { ApiResponse } from "./response";

export async function apiFetch<T>(input: RequestInfo, init?: RequestInit): Promise<ApiResponse<T>> {
  const headers = getApiHeaders();
  const res = await fetch(input, { ...init, headers: { ...init?.headers, ...headers } });
  return res.json();
}

export async function plainFetch<T>(input: RequestInfo, init?: RequestInit): Promise<T> {
  const res = await fetch(input, init);
  return res.json();
}

export async function authFetch<T>(
  input: RequestInfo,
  authProvider: IPublicClientApplication,
  init?: RequestInit,
): Promise<T> {
  const headers = await getAuthHeaders(authProvider);
  const res = await fetch(input, { ...init, method: "GET", headers: { ...init?.headers, ...headers } });
  return res.json();
}

export async function authFetchPost<T>(
  input: RequestInfo,
  authProvider: IPublicClientApplication,
  init?: RequestInit,
): Promise<T> {
  const headers = await getAuthHeaders(authProvider);
  const res = await fetch(input, { ...init, method: "POST", headers: { ...init?.headers, ...headers } });
  return res.json();
}

export default apiFetch;
