import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import { InputAdornment, IconButton } from "@material-ui/core";

interface Props {
  value?: string;
  onChange: (productId: string | null) => void;
  size?: "small" | "medium";
}
export const ProductIdFilter = ({ value, onChange, size = "medium" }: Props): JSX.Element => {
  const [productIdValue, setProductIdValue] = useState(value || "");
  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onChange(productIdValue);
    }
  };

  return (
    <TextField
      label="Tuotenro"
      variant="outlined"
      value={productIdValue}
      onKeyPress={handleKeyPress}
      onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setProductIdValue(event.target.value);
      }}
      onBlur={() => {
        onChange(productIdValue);
      }}
      size={size}
      style={{ width: 150 }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {productIdValue && (
              <IconButton
                onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setProductIdValue("");
                  onChange(null);
                }}
                edge="end"
                size={size}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};
