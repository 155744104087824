import React from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  Link,
  Hidden,
  IconButton,
  AppBar,
  Toolbar,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { Link as RouterLink } from "react-router-dom";
import { Avatar } from "./Avatar";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import { useUserGroups } from "../../hooks/useUserGroups";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import StoreSelector from "./StoreSelector";

interface Props {
  title: string;
  loggedUser?: string | undefined;
  login: () => void;
  logout: () => void;
  toggleDrawerOpen: () => void;
  drawerOpen: boolean;
}

const LoginButton = ({ login }: { login: () => void }) => (
  <>
    <Hidden only="xs">
      <Button variant="contained" color="secondary" onClick={login}>
        Kirjaudu sisään
      </Button>
    </Hidden>
    <Hidden smUp>
      <IconButton color="secondary" onClick={login}>
        <PowerSettingsNewIcon fontSize="small" />
      </IconButton>
    </Hidden>
  </>
);

const LogoutButton = ({ logout }: { logout: () => void }) => (
  <>
    <Hidden only="xs">
      <Button variant="contained" color="secondary" onClick={logout}>
        Kirjaudu ulos
      </Button>
    </Hidden>
    <Hidden smUp>
      <IconButton color="secondary" onClick={logout}>
        <PowerSettingsNewIcon fontSize="small" />
      </IconButton>
    </Hidden>
  </>
);

const drawerWidth = 200;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1000,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginLeft: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    hide: {
      display: "none",
    },
  }),
);

const AlkoHeader = ({ title, loggedUser, login, logout, toggleDrawerOpen, drawerOpen }: Props): JSX.Element => {
  const classes = useStyles();
  const { userGroups } = useUserGroups();
  const iconButtonDisabled = !loggedUser;
  const hasValidUserGroups = loggedUser && userGroups && userGroups.length > 0;

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar disableGutters>
          <Box py={1} width={1}>
            <Grid container direction="row" alignItems="center" justify="center" wrap="nowrap">
              <Grid item>
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <IconButton
                      color="inherit"
                      aria-label="Avaa valikko"
                      onClick={() => toggleDrawerOpen()}
                      edge="end"
                      className={classes.menuButton}
                      disabled={iconButtonDisabled}
                    >
                      {!drawerOpen ? <MenuIcon /> : <CloseIcon />}
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Box pl={{ xs: 2, sm: 4 }}>
                      <Box
                        width={{ xs: 30, sm: 60 }}
                        height={{ xs: 30, sm: 60 }}
                        className="icon-alko-logo-red"
                        display="inline-flex"
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box px={{ xs: 1, md: 4 }} flexGrow={1} display="inline-flex">
                      <Typography variant="h1" style={{ color: "white" }}>
                        <Link component={RouterLink} to="/" style={{ color: "white" }} underline="none">
                          {title}
                        </Link>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs>
                <Grid container justify="flex-end" alignItems="flex-end" direction="row">
                  <Grid item xs>
                    <Grid container direction="row" alignItems="center" justify="flex-end">
                      <Grid item xs>
                        <Box px={2} display="flex" flexDirection="column" alignItems="flex-end">
                          <AuthenticatedTemplate>{hasValidUserGroups && <StoreSelector />}</AuthenticatedTemplate>
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box px={2}>
                          <AuthenticatedTemplate>
                            <Avatar name={loggedUser ?? null} popup={<LogoutButton logout={logout} />} />
                          </AuthenticatedTemplate>
                          <UnauthenticatedTemplate>
                            <LoginButton login={login} />
                          </UnauthenticatedTemplate>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default AlkoHeader;
