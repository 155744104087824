import React from "react";
import Box from "@material-ui/core/Box";
import useSWR, { ConfigInterface } from "swr";
import { plainFetch as fetch } from "../../utils/fetch";
import { getApiUrl } from "../../utils/request";

interface VersionInfo {
  release: string;
  source: string;
}

export const Footer = (): JSX.Element => {
  const swrOptions: ConfigInterface = { revalidateOnFocus: false };
  const { data: apiVersion } = useSWR<VersionInfo>(getApiUrl("/v1/version"), fetch, swrOptions);
  const { data: frontendVersion } = useSWR<VersionInfo>(
    process.env.PUBLIC_URL + "/frontend_version.json",
    fetch,
    swrOptions,
  );

  return (
    <Box p={4} display="flex" justifyContent="center" style={{ color: "#aaa", fontSize: 10 }}>
      Frontend: {frontendVersion?.release} - {frontendVersion?.source.substring(0, 7)}, API: {apiVersion?.release} -{" "}
      {apiVersion?.source.substring(0, 7)}
    </Box>
  );
};
