import React from "react";
import MuiAvatar from "@material-ui/core/Avatar";
import { makeStyles, Theme, createStyles, Popover, IconButton, Tooltip } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    avatar: {
      color: theme.palette.getContrastText(grey[600]),
      backgroundColor: grey[600],
    },
  }),
);

const getInitials = (name?: string) => {
  if (!name) return "";
  const initialsArray = name
    .split(" ")
    .filter((s) => s !== "EXT")
    .map((s) => s[0].toUpperCase());
  return initialsArray.length > 1
    ? `${initialsArray[0]}${initialsArray[initialsArray.length - 1]}`
    : initialsArray.length > 0
    ? initialsArray[0]
    : null;
};

interface Props {
  name?: string | null;
  popup: JSX.Element;
}
export const Avatar = ({ name, popup }: Props): JSX.Element => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  if (!name) {
    return <React.Fragment />;
  }

  return (
    <>
      <IconButton onClick={handleClick}>
        <Tooltip title={`Kirjautunut: ${name}`} placement="left" arrow disableFocusListener disableTouchListener>
          <MuiAvatar className={classes.avatar}>{getInitials(name)}</MuiAvatar>
        </Tooltip>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        style={{ zIndex: 3000 }}
      >
        {popup}
      </Popover>
    </>
  );
};
