import useSWR from "swr";
import { getApiUrl } from "../utils/request";
import { ProductGroup } from "../api/productGroup";
import fetch from "../utils/fetch";
import { ApiResponse } from "../utils/response";

const collator = new Intl.Collator(["fi", "sv"]);

export function useAllProductGroups(): { productGroups: ProductGroup[]; isLoading: boolean; isError: boolean } {
  const { data, error } = useSWR<ApiResponse<ProductGroup[]>>(getApiUrl("/v1/productGroups"), fetch);

  const productGroups: ProductGroup[] = data ? data.data : [];
  if (productGroups.length) {
    productGroups.sort((a: ProductGroup, b: ProductGroup) =>
      collator.compare(a.productGroupName.fi || "", b.productGroupName.fi || ""),
    );
  }

  return {
    productGroups: productGroups,
    isLoading: !error && !data,
    isError: error,
  };
}
