/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import agentsReducer from "./state/agents";

const store = configureStore({
  reducer: {
    agents: agentsReducer,
  },
  middleware: [...getDefaultMiddleware()] as const,
  devTools: true,
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be resused to resolve types

export default store;
