import React from "react";
import { Typography, Box } from "@material-ui/core";

export const Unauthorized = (): JSX.Element => {
  return (
    <Box alignSelf="center" textAlign="center">
      <Typography variant="h3">Ei käyttöoikeutta</Typography>
    </Box>
  );
};
