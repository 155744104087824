import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import format from "date-fns/format";
import { fi } from "date-fns/locale";
import { IconButton, TextField, Tooltip, CircularProgress, Link } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import EditIcon from "@material-ui/icons/Edit";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import { useForm, Controller } from "react-hook-form";
import { Select } from "../components/common/Select";
import { Agent, AgentStatusOption, AgentStatus, statuses } from "../api/agent";
import { RootState, useAppDispatch } from "../reduxStore";
import { fetchAgents, editAgents, resetUpdatingState } from "../state/agents";
import { LoadingState } from "../state/loadingState";
import { TableToolbar } from "../components/common/table/TableToolbar";
import { TableHead, HeadCell } from "../components/common/table/TableHead";
import { OrderDirection } from "../utils/order";
import { MultiRowActions } from "../components/agents/MultiRowActions";
import { AgentFilterToolbar } from "../components/agents/AgentFilterToolbar";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { AgentStatusValue } from "../components/common/AgentStatusValue";

const headCells: HeadCell[] = [
  { id: "agent.agentId", numeric: false, sortable: true, disablePadding: false, label: "Toimittaja" },
  { id: "agent.name", numeric: false, sortable: true, disablePadding: false, label: "Nimi" },
  { id: "status", numeric: false, sortable: true, disablePadding: false, label: "Tila" },
  { id: "comment", numeric: false, sortable: false, disablePadding: false, label: "Kommentti" },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
  }),
);

interface EditRowInput {
  status: string;
  comment: string;
}

export const ListAgents = ({ loggedUserName }: { loggedUserName?: string }): JSX.Element => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState<OrderDirection>("asc");
  const [orderBy, setOrderBy] = useState<string>("name");
  const [selected, setSelected] = useState<string[]>([]);
  const filters = useSelector((state: RootState) => state.agents.filters);
  const agents = useSelector((state: RootState) => state.agents.agents);
  const loading = useSelector((state: RootState) => state.agents.loading);
  const count = useSelector((state: RootState) => state.agents.count);
  const updating = useSelector((state: RootState) => state.agents.updating);
  const [editRow, setEditRow] = useState("");
  const { handleSubmit, register, control } = useForm<EditRowInput>();
  const { agentFilters, wishFilters, setWishFilters } = useLocalStorage();
  const history = useHistory();

  useEffect(() => {
    const queryFilters = {
      agentId: filters.agentId || agentFilters?.agentId,
      status: filters.status || agentFilters?.status,
    };
    dispatch(fetchAgents({ page, pageSize: rowsPerPage, orderBy, orderDirection: order, filters: queryFilters }));
  }, [dispatch, page, rowsPerPage, order, orderBy, filters.agentId, filters.status, agentFilters]);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = agents.map((ag) => ag.agentId);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleUpdateSingle = (values: EditRowInput) => {
    dispatch(
      editAgents([
        {
          agentId: editRow,
          status: statuses.find((s: AgentStatusOption) => s.value === values.status)?.value || AgentStatus.ACTIVE,
          comment: values.comment,
          updatedBy: loggedUserName,
        },
      ]),
    );
  };

  const handleUpdateMultiple = (statusForSelectedRows: string) => {
    const status =
      statuses.find((s: AgentStatusOption) => s.value === statusForSelectedRows)?.value || AgentStatus.ACTIVE;
    dispatch(editAgents(selected.map((agentId) => ({ agentId, status, updatedBy: loggedUserName }))));
  };

  const clearEditRow = () => {
    setEditRow("");
  };

  useEffect(() => {
    if (!!editRow && updating === LoadingState.Succeeded) {
      dispatch(resetUpdatingState());
      clearEditRow();
    }
  }, [dispatch, editRow, updating]);

  useEffect(() => {
    if (selected.length > 0 && updating === LoadingState.Succeeded) {
      dispatch(resetUpdatingState());
      setSelected([]);
    }
  }, [dispatch, selected.length, updating]);

  const handleRowSelect = (agentId: string) => {
    const selectedIndex = selected.indexOf(agentId);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, agentId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const isSelected = (agentId: string) => selected.indexOf(agentId) !== -1;

  const handleOpenAgentWishes = (agentId: string) => {
    setWishFilters({ ...wishFilters, agentId });
    history.push("/wishes");
  };

  const AgentWishesLink = ({ text, agentId }: { text: string; agentId: string }) => {
    return (
      <Link
        href="#"
        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
          e.preventDefault();
          handleOpenAgentWishes(agentId);
        }}
        underline="hover"
      >
        {text}
      </Link>
    );
  };

  return (
    <div className={classes.root}>
      <TableToolbar
        hasSelections={selected.length > 0}
        actionsComponent={<MultiRowActions selected={selected} handleUpdateMultiple={handleUpdateMultiple} />}
        filtersComponent={<AgentFilterToolbar />}
      />
      <Paper className={classes.paper}>
        <TableContainer>
          <form onSubmit={handleSubmit(handleUpdateSingle)}>
            <Table className={classes.table} size="small">
              <TableHead
                headCells={headCells}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={agents.length}
                selectAllDisabled={!!editRow}
              />
              <TableBody>
                {agents.map((agent: Agent, index: number) => {
                  const isItemSelected = isSelected(agent.agentId);
                  const isEditing = !!editRow && editRow === agent.agentId.toString();
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={agent.agentId}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          disabled={!!editRow}
                          inputProps={{ "aria-label": `Valitse toimittaja ${agent.agentId}` }}
                          onChange={() => handleRowSelect(agent.agentId)}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <Tooltip
                          title={`Muokattu: ${format(new Date(agent.updated), "Pp", { locale: fi })} ${
                            agent.updatedBy || ""
                          }`}
                          enterDelay={1000}
                        >
                          <span>
                            <AgentWishesLink agentId={agent.agentId} text={agent.agentId} />
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="left">
                        <AgentWishesLink agentId={agent.agentId} text={agent.name} />
                      </TableCell>
                      <TableCell align="left">
                        {isEditing ? (
                          <Controller
                            name="status"
                            control={control}
                            defaultValue={agent.status}
                            rules={{ required: true }}
                            render={(props) => (
                              <Select
                                id={`statusForAgent-${agent.agentId}`}
                                label=""
                                options={statuses}
                                variant="standard"
                                size="small"
                                {...props}
                              />
                            )} // props contains: onChange, onBlur and value
                          />
                        ) : (
                          <AgentStatusValue
                            status={statuses.find((s) => s.value === agent.status)}
                            updated={new Date(agent.updated)}
                            showDate
                          />
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {isEditing ? (
                          <TextField
                            id={`commentForAgent-${agent.agentId}`}
                            name="comment"
                            defaultValue={agent.comment}
                            variant="standard"
                            inputRef={register}
                            size="small"
                          />
                        ) : (
                          <Tooltip title={agent.comment || ""} enterDelay={1000}>
                            <Box
                              maxWidth={120}
                              maxHeight={40}
                              whiteSpace="nowrap"
                              textOverflow="ellipsis"
                              overflow="hidden"
                            >
                              {agent.comment}
                            </Box>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell style={{ width: 100 }} align="right">
                        <Box display="flex" alignContent="center" justifyContent="flex-end">
                          {isEditing ? (
                            <>
                              {updating === LoadingState.Pending ? (
                                <CircularProgress size={24} />
                              ) : (
                                <>
                                  <Tooltip title="Tallenna" enterDelay={1000}>
                                    <IconButton
                                      type="submit"
                                      value={agent.agentId}
                                      disabled={!!editRow && editRow !== agent.agentId.toString()}
                                      size="small"
                                    >
                                      <CheckCircleOutlineIcon fontSize="default" />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Peruuta" enterDelay={1000}>
                                    <IconButton
                                      disabled={!!editRow && editRow !== agent.agentId.toString()}
                                      size="small"
                                      onClick={() => {
                                        clearEditRow();
                                      }}
                                    >
                                      <CloseIcon fontSize="default" />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              )}
                            </>
                          ) : (
                            <Tooltip title="Muokkaa" enterDelay={1000}>
                              <IconButton
                                disabled={selected.length > 0 || (!!editRow && editRow !== agent.agentId.toString())}
                                size="small"
                                onClick={() => {
                                  setEditRow(agent.agentId.toString());
                                }}
                              >
                                <EditIcon fontSize="default" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {loading === LoadingState.Pending && (
                  <TableRow style={{ height: 63 }}>
                    <TableCell colSpan={headCells.length + 2} align="center" valign="middle">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </form>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onChangePage={handleChangePage}
        />
      </Paper>
    </div>
  );
};
