import { ApiResponse } from "../utils/response";
import { getFromApi } from "../utils/request";

export interface ProductGroup {
  mainGroupId: string;
  mainGroupName: { fi?: string; sv?: string; en?: string };
  productGroupId: string;
  productGroupName: { fi?: string; sv?: string; en?: string };
}

export const getProductGroups = async (): Promise<ApiResponse<ProductGroup[]>> => {
  return await getFromApi<ProductGroup[]>("/v1/productGroups");
};

export interface ProductGroupOption {
  id: string;
  name: {
    fi: string;
    sv: string;
    en: string;
  };
  productGroupIds: string[];
}
export const productGroupsForOpenWish: ProductGroupOption[] = [
  {
    id: "redwines",
    name: {
      fi: "Punaviinit",
      sv: "Rödvin",
      en: "Red wine",
    },
    productGroupIds: ["productGroup_110"],
  },
  {
    id: "rosewines",
    name: {
      fi: "Roseeviinit",
      sv: "Rosévin",
      en: "Rosé wine",
    },
    productGroupIds: ["productGroup_120"],
  },
  {
    id: "whitewines",
    name: {
      fi: "Valkoviinit",
      sv: "Vitvin",
      en: "White wine",
    },
    productGroupIds: ["productGroup_130"],
  },
  {
    id: "sparkling-champagne",
    name: {
      fi: "Kuohuviinit ja Samppanjat",
      sv: "Mousserande vin & Champagne",
      en: "Sparkling wine & Champagne",
    },
    productGroupIds: ["productGroup_B10"],
  },
  {
    id: "dessert-other",
    name: {
      fi: "Jälkiruokaviinit, väkevöidyt ja muut viinit",
      sv: "Dessertvin, starkvin och andra viner",
      en: "Dessert wine & other fortified wines",
    },
    productGroupIds: [
      "productGroup_J10",
      "productGroup_160",
      "productGroup_195",
      "productGroup_310",
      "productGroup_320",
      "productGroup_330",
      "productGroup_340",
      "productGroup_350",
      "productGroup_360",
      "productGroup_370",
      "productGroup_H30",
      "productGroup_S10",
      "productGroup_S60",
    ],
  },
  {
    id: "beer",
    name: {
      fi: "Oluet",
      sv: "Öl",
      en: "Beer",
    },
    productGroupIds: ["productGroup_600"],
  },
  {
    id: "cider",
    name: {
      fi: "Siiderit",
      sv: "Cider",
      en: "Cider",
    },
    productGroupIds: ["productGroup_645"],
  },
  {
    id: "mixeddrinks",
    name: {
      fi: "Juomasekoitukset",
      sv: "Drinkar",
      en: "Mixed drinks",
    },
    productGroupIds: ["productGroup_650"],
  },
  {
    id: "vodka-spirits",
    name: {
      fi: "Vodkat ja viinat",
      sv: "Vodka och brännvin",
      en: "Vodka & spirit",
    },
    productGroupIds: ["productGroup_411", "productGroup_413", "productGroup_415", "productGroup_S90"],
  },
  {
    id: "gin-other",
    name: {
      fi: "Ginit ja muut viinat",
      sv: "Gin och övriga brännvin",
      en: "Gin and other spirits",
    },
    productGroupIds: [
      "productGroup_418",
      "productGroup_419",
      "productGroup_420",
      "productGroup_421",
      "productGroup_422",
      "productGroup_423",
      "productGroup_424",
      "productGroup_425",
      "productGroup_S30",
    ],
  },
  {
    id: "rum",
    name: {
      fi: "Rommit",
      sv: "Rom",
      en: "Rum",
    },
    productGroupIds: ["productGroup_428"],
  },
  {
    id: "cognac",
    name: {
      fi: "Konjakit",
      sv: "Konjak",
      en: "Cognacs",
    },
    productGroupIds: ["productGroup_432"],
  },
  {
    id: "brandy-armagnac-calvados",
    name: {
      fi: "Brandyt, Armanjakit ja Calvadosit",
      sv: "Brandy, Armagnac och Calvados",
      en: "Brandy, Armagnac & Calvados",
    },
    productGroupIds: ["productGroup_436", "productGroup_437", "productGroup_439", "productGroup_S20"],
  },
  {
    id: "whisky",
    name: {
      fi: "Viskit",
      sv: "Whisky",
      en: "Whisky",
    },
    productGroupIds: ["productGroup_440"],
  },
  {
    id: "liquer-bitter",
    name: {
      fi: "Liköörit ja katkerot",
      sv: "Likör och bitter",
      en: "Liqueur & bitter",
    },
    productGroupIds: [
      "productGroup_470",
      "productGroup_F10",
      "productGroup_F15",
      "productGroup_F20",
      "productGroup_F25",
      "productGroup_F30",
      "productGroup_F40",
      "productGroup_F50",
      "productGroup_F60",
      "productGroup_F70",
      "productGroup_F80",
      "productGroup_S40",
    ], // liköörit ja katkerot
  },
  {
    id: "nonalcoholic",
    name: {
      fi: "Alkoholittomat",
      sv: "Alkoholfritt",
      en: "Non-alcoholic",
    },
    productGroupIds: [
      "productGroup_710",
      "productGroup_910",
      "productGroup_920",
      "productGroup_930",
      "productGroup_940",
      "productGroup_950",
      "productGroup_960",
      "productGroup_965",
    ],
  },
  {
    id: "gifts-accessories",
    name: {
      fi: "Lahja- ja juomatarvikkeet",
      sv: "Presenter och dryckestillbehör",
      en: "Gifts & accessories",
    },
    productGroupIds: ["productGroup_901", "productGroup_902", "productGroup_903"],
  },
];
