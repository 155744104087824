import React from "react";
import { WishStatus, WishStatusOption } from "../../api/wish";
import { Typography } from "@material-ui/core";

interface Props {
  status?: WishStatusOption;
}
export const WishStatusValue = ({ status }: Props): JSX.Element | null => {
  if (!status) return null;
  if (status.value === WishStatus.BLOCKED) {
    return (
      <Typography variant="body2" noWrap color="error">
        {status.name}
      </Typography>
    );
  }
  if (status.value === WishStatus.WARNING) {
    return (
      <Typography variant="body2" noWrap style={{ color: "#c80" }}>
        {status.name}
      </Typography>
    );
  }
  return (
    <Typography variant="body2" noWrap>
      {status.name}
    </Typography>
  );
};
