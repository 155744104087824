import queryString from "query-string";
import { API_URL, API_KEY } from "../config";
import { AuthError, IPublicClientApplication } from "@azure/msal-browser";
import { ApiResponse } from "./response";
import isEmpty from "lodash/isEmpty";
import { loginRequest } from "../authConfig";

// Typeguards for Errors to avoid linting errors
export function getError(err: Error | AuthError | any): Error | AuthError {
  if (err instanceof AuthError || (err && err.errorCode)) return err as AuthError;
  return err as Error;
}

export const getAuthHeaders = async (authProvider: IPublicClientApplication): Promise<Record<string, string>> => {
  let accessToken;
  const account = authProvider.getActiveAccount();
  if (!account) {
    throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
  }

  try {
    const response = await authProvider.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });

    accessToken = response.accessToken;
  } catch (err) {
    const error = getError(err) as AuthError;
    if (
      error.errorCode === "consent_required" ||
      error.errorCode === "interaction_required" ||
      error.errorCode === "login_required"
    ) {
      accessToken = await authProvider.acquireTokenRedirect(loginRequest);
    }
  }

  return {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  };
};

export interface Filter {
  field: string;
  value: string | string[];
}

export interface Query {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export const getApiUrl = (path: string, query?: Query): string => {
  let filters: string[] = [];
  if (query?.filters && !isEmpty(query?.filters)) {
    filters = Object.keys(query?.filters).filter((key) => !isEmpty(query.filters[key]));
  }
  const url = queryString.stringifyUrl(
    {
      url: `${API_URL}${path}`,
      query: { ...query, filters, ...query?.filters },
    },
    { skipNull: true, skipEmptyString: true, arrayFormat: "comma" },
  );

  return url;
};

export const getApiHeaders = (): Record<string, string> => {
  return {
    "Content-Type": "application/json",
    "x-functions-key": API_KEY,
  };
};

export const getFromApi = async <T>(path: string, query?: Query): Promise<ApiResponse<T>> => {
  const headers = getApiHeaders();
  const response = await fetch(getApiUrl(path, query), { headers });
  if (!response.ok) {
    const { error } = await response.json();
    throw new Error(error);
  }
  return (await response.json()) as ApiResponse<T>;
};

export const postToApi = async <T>(path: string, body: unknown): Promise<ApiResponse<T>> => {
  const headers = getApiHeaders();
  const response = await fetch(getApiUrl(path), {
    method: "POST",
    headers,
    body: JSON.stringify(body),
  });
  if (!response.ok) {
    const { error } = await response.json();
    throw new Error(error);
  }
  return (await response.json()) as ApiResponse<T>;
};

export const putToApi = async <T>(path: string, body: unknown): Promise<ApiResponse<T>> => {
  const headers = getApiHeaders();
  const response = await fetch(getApiUrl(path), {
    method: "PUT",
    headers,
    body: JSON.stringify(body),
  });
  if (!response.ok) {
    const { error } = await response.json();
    throw new Error(error);
  }
  return (await response.json()) as ApiResponse<T>;
};
