import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useAllProductGroups } from "../../hooks/useAllProductGroups";
import { ProductGroup } from "../../api/productGroup";

interface Props {
  value?: string;
  onChange: (productGroupId: string | null) => void;
  size?: "small" | "medium";
}
export const ProductGroupFilter = ({ value, onChange, size }: Props): JSX.Element => {
  const { productGroups, isLoading } = useAllProductGroups();
  return (
    <Autocomplete
      options={productGroups}
      getOptionLabel={(productGroup: ProductGroup) => productGroup.productGroupName.fi || ""}
      renderInput={(params) => <TextField {...params} label="Tuoteryhmä" variant="outlined" />}
      value={productGroups.find((p: ProductGroup) => p.productGroupId === value) || null}
      style={{ width: 250 }}
      size={size}
      autoHighlight
      onChange={(_: unknown, value: ProductGroup | null) => onChange(value?.productGroupId || null)}
      loading={isLoading}
      loadingText="Ladataan..."
    />
  );
};
