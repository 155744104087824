import React from "react";
import { Box, CircularProgress } from "@material-ui/core";

export const Loading = (): JSX.Element => {
  return (
    <Box alignSelf="center" textAlign="center">
      <CircularProgress color="secondary" />
    </Box>
  );
};
