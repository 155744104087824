import React from "react";
import { AgentStatus } from "../api/agent";
import { WishStatusOption } from "../api/wish";

const STORE_LOCAL_STORAGE_KEY = "wishAdminStore";
const WISH_FILTERS_LOCAL_STORAGE_KEY = "wishAdminWishFilters";
const AGENT_FILTERS_LOCAL_STORAGE_KEY = "wishAdminAgentFilters";

export const getSelectedStore = (): string | null => {
  const savedStoreId = window.localStorage.getItem(STORE_LOCAL_STORAGE_KEY);
  if (savedStoreId) return savedStoreId;
  return null;
};

export const getSavedWishFilters = (): Record<string, unknown> | null => {
  const savedWishFilters = window.localStorage.getItem(WISH_FILTERS_LOCAL_STORAGE_KEY);
  if (savedWishFilters) return JSON.parse(savedWishFilters);
  return null;
};

export const getSavedAgentFilters = (): Record<string, unknown> | null => {
  const savedAgentFilters = window.localStorage.getItem(AGENT_FILTERS_LOCAL_STORAGE_KEY);
  if (savedAgentFilters) return JSON.parse(savedAgentFilters);
  return null;
};

interface WishFilters {
  createdStart?: string | null;
  createdEnd?: string | null;
  status?: WishStatusOption[];
  agentId?: string;
  productId?: string;
  productGroup?: string;
}

interface AgentFilters {
  status?: AgentStatus;
  agentId?: string;
}

interface LocalStorageContextType {
  storeId: string | null;
  setStoreId: (id: string | null) => void;
  wishFilters: WishFilters | null;
  setWishFilters: (value: WishFilters | null) => void;
  agentFilters: AgentFilters | null;
  setAgentFilters: (value: AgentFilters | null) => void;
}
const LocalStorageContext = React.createContext<LocalStorageContextType>({
  storeId: null,
  setStoreId: (id: string | null) => null,
  wishFilters: null,
  setWishFilters: (value: WishFilters | null) => null,
  agentFilters: null,
  setAgentFilters: (value: AgentFilters | null) => null,
});

interface Props {
  children: React.ReactNode;
}

export const LocalStorageProvider = ({ children }: Props): JSX.Element => {
  const [storeState, setStoreState] = React.useState(getSelectedStore());
  const [wishFiltersState, setWishFiltersState] = React.useState<WishFilters | null>(getSavedWishFilters());
  const [agentFiltersState, setAgentFiltersState] = React.useState<AgentFilters | null>(getSavedAgentFilters());

  const setStore = (id: string | null) => {
    if (id) {
      window.localStorage.setItem(STORE_LOCAL_STORAGE_KEY, id);
    } else {
      window.localStorage.removeItem(STORE_LOCAL_STORAGE_KEY);
    }
    setStoreState(id);
  };

  const setWishFilters = (value: WishFilters | null) => {
    if (value && typeof value === "object" && Object.keys(value).length > 0) {
      window.localStorage.setItem(WISH_FILTERS_LOCAL_STORAGE_KEY, JSON.stringify(value));
    } else {
      window.localStorage.removeItem(WISH_FILTERS_LOCAL_STORAGE_KEY);
    }
    setWishFiltersState(value);
  };

  const setAgentFilters = (value: AgentFilters | null) => {
    if (value && typeof value === "object" && Object.keys(value).length > 0) {
      window.localStorage.setItem(AGENT_FILTERS_LOCAL_STORAGE_KEY, JSON.stringify(value));
    } else {
      window.localStorage.removeItem(AGENT_FILTERS_LOCAL_STORAGE_KEY);
    }
    setAgentFiltersState(value);
  };

  return (
    <LocalStorageContext.Provider
      value={{
        storeId: storeState,
        setStoreId: setStore,
        wishFilters: wishFiltersState,
        setWishFilters,
        agentFilters: agentFiltersState,
        setAgentFilters,
      }}
    >
      {children}
    </LocalStorageContext.Provider>
  );
};

export const useLocalStorage = (): LocalStorageContextType => React.useContext(LocalStorageContext);
