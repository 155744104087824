import React from "react";
import formatISO from "date-fns/formatISO";
import { DatePicker } from "@material-ui/pickers";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import { InputAdornment, TextField } from "@material-ui/core";

interface Props {
  startValue: string | null;
  endValue: string | null;
  onChangeStart: (start: string | null) => void;
  onChangeEnd: (end: string | null) => void;
  size?: "small" | "medium";
}
export const DateRangeFilter = ({
  startValue,
  endValue,
  onChangeStart,
  onChangeEnd,
  size = "medium",
}: Props): JSX.Element => {
  return (
    <Box display="flex" flexDirection="row" justifyItems="center">
      <DatePicker
        autoOk
        disableToolbar
        disableFuture
        format="dd.MM.yyyy"
        inputVariant="outlined"
        label="Alkupvm"
        variant="inline"
        size={size}
        value={startValue}
        onChange={(date) => onChangeStart(date ? formatISO(date).substring(0, 10) : null)}
        style={{ maxWidth: 160 }}
        TextFieldComponent={(props) => (
          <TextField
            {...props}
            InputProps={{
              endAdornment: startValue && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onChangeStart(null);
                    }}
                    edge="end"
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
      <DatePicker
        autoOk
        disableToolbar
        disableFuture
        format="dd.MM.yyyy"
        inputVariant="outlined"
        label="Loppupvm"
        variant="inline"
        value={endValue}
        minDate={startValue}
        onChange={(date) => onChangeEnd(date ? formatISO(date).substring(0, 10) : null)}
        style={{ maxWidth: 160 }}
        size={size}
        TextFieldComponent={(props) => (
          <TextField
            {...props}
            InputProps={{
              endAdornment: endValue && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onChangeEnd(null);
                    }}
                    edge="end"
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};
