import countries from "./fi.json";

// JSON files originally from:
// https://github.com/michaelwittig/node-i18n-iso-countries/tree/master/langs
//
// If update is needed, please check the manually added entries for GB-* .
//
export interface Country {
  value: string;
  name: string;
  flag?: string;
}

function countryToFlag(isoCode: string) {
  if (isoCode.toUpperCase().startsWith("GB-")) {
    switch (isoCode.toUpperCase()) {
      case "GB-ENG":
        return "🏴󠁧󠁢󠁥󠁮󠁧󠁿";
      case "GB-SCT":
        return "🏴󠁧󠁢󠁳󠁣󠁴󠁿";
      case "GB-WLS":
        return "🏴󠁧󠁢󠁷󠁬󠁳󠁿";
      case "GB-NIR":
      // intentional no-op, since Northern Ireland does not have own flag
    }
  }
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .substring(0, 2)
        .toUpperCase()
        .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : undefined;
}

export const getCountries = (): Country[] => {
  const countryOptions = Object.entries(countries.countries).map(([value, name]) => ({
    value,
    name: name.toString(),
    flag: countryToFlag(value),
  }));
  return countryOptions;
};
