import dotenv from "dotenv";

dotenv.config();

export const API_URL = process.env.REACT_APP_API_URL || "";
export const API_KEY = process.env.REACT_APP_API_KEY || "";
export const GRAPH_API_URL = "https://graph.microsoft.com/v1.0";
export const WISH_ADMIN_GROUPS = process.env.REACT_APP_WISH_ADMIN_GROUPS
  ? process.env.REACT_APP_WISH_ADMIN_GROUPS.split(",")
  : [];
export const AGENT_ADMIN_GROUPS = process.env.REACT_APP_AGENT_ADMIN_GROUPS
  ? process.env.REACT_APP_AGENT_ADMIN_GROUPS.split(",")
  : [];
export const ENV = process.env.NODE_ENV === "development" ? "dev" : "";
