import React from "react";

import { ThemeProvider, Theme, createMuiTheme, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Store } from "../../api/store";
import { useAllStores } from "../../hooks/useAllStores";
import { useLocalStorage } from "../../hooks/useLocalStorage";

const StoreSelector = (): JSX.Element => {
  const { stores, isLoading } = useAllStores();
  const { storeId, setStoreId } = useLocalStorage();
  const selectedStore = stores.find((s: Store) => s.id === storeId) || null;

  return (
    <ThemeProvider
      theme={(theme: Theme) =>
        createMuiTheme({
          ...theme,
          palette: {
            type: "dark",
            primary: {
              light: "#e5e5e5",
              main: "#e5e5e5",
              dark: "#6d6d6d",
              contrastText: "#000000",
            },
          },
        })
      }
    >
      <Autocomplete
        value={selectedStore || { id: null, name: "Kaikki myymälät" }}
        options={[{ id: null, name: "Kaikki myymälät" }, ...stores]}
        disableClearable={!selectedStore}
        getOptionLabel={(store: Store) => (store.id ? `${store.id} ${store.name}` : "Kaikki myymälät")}
        getOptionSelected={(option: Store, value: Store) => option.id === value.id}
        renderInput={(params) => <TextField {...params} label="Myymälä" variant="outlined" color="primary" />}
        fullWidth
        style={{ maxWidth: 300 }}
        autoHighlight
        disablePortal
        onChange={(_: unknown, value: Store | null) => setStoreId(value?.id || null)}
        loading={isLoading}
        loadingText="Ladataan..."
      />
    </ThemeProvider>
  );
};

export default StoreSelector;
