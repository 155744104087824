import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Agent, FindAgentsParams, updateAgents, UpdateAgentParams, findAgents, AgentFilter } from "../api/agent";
import { ApiResponse } from "../utils/response";
import { LoadingState } from "./loadingState";

export const fetchAgents = createAsyncThunk<
  ApiResponse<Agent[]>,
  FindAgentsParams,
  {
    rejectValue: string;
  }
>("agents/find", async (params, thunkApi) => {
  try {
    return await findAgents(params);
  } catch (error) {
    console.error(error);
    return thunkApi.rejectWithValue(error.message);
  }
});

export const editAgents = createAsyncThunk<
  ApiResponse<Agent[]>,
  UpdateAgentParams[],
  {
    rejectValue: string;
  }
>("agents/edit", async (params, thunkApi) => {
  try {
    const response = await updateAgents(params);
    return response;
  } catch (error) {
    console.error(error);
    return thunkApi.rejectWithValue(error.message);
  }
});

interface AgentsState {
  allAgents: Agent[];
  agents: Agent[];
  count: number;
  loading: LoadingState;
  updating: LoadingState;
  error?: string;
  filters: AgentFilter;
}

const initialState: AgentsState = {
  allAgents: [],
  agents: [],
  count: 0,
  loading: LoadingState.Idle,
  updating: LoadingState.Idle,
  error: "",
  filters: {},
};

const agentsSlice = createSlice({
  name: "agents",
  initialState,
  reducers: {
    resetUpdatingState: (state) => {
      state.updating = LoadingState.Idle;
      state.error = "";
    },
    setAgentFilter: (state, action) => {
      state.filters.agentId = action.payload;
    },
    setStatusFilter: (state, action) => {
      state.filters.status = state.filters.status !== action.payload ? action.payload : null;
    },
    resetFilters: (state) => {
      state.filters = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAgents.fulfilled, (state, action: PayloadAction<ApiResponse<Agent[]>>) => {
      const agents = action.payload.data;
      const collator = new Intl.Collator(["fi", "sv"]);
      agents.sort((a: Agent, b: Agent) => collator.compare(a.name, b.name));
      state.agents = agents;
      state.count = action.payload.count || 0;
      state.loading = LoadingState.Succeeded;
    });
    builder.addCase(fetchAgents.pending, (state) => {
      state.loading = LoadingState.Pending;
    });
    builder.addCase(fetchAgents.rejected, (state, action) => {
      state.loading = LoadingState.Failed;
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error.message;
      }
    });
    builder.addCase(editAgents.fulfilled, (state, action: PayloadAction<ApiResponse<Agent[]>>) => {
      const { data } = action.payload;
      state.agents = state.agents.map((agent: Agent) => data.find((a: Agent) => agent.agentId === a.agentId) || agent);
      state.updating = LoadingState.Succeeded;
    });
    builder.addCase(editAgents.pending, (state) => {
      state.updating = LoadingState.Pending;
    });
    builder.addCase(editAgents.rejected, (state, action) => {
      state.updating = LoadingState.Failed;
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error.message;
      }
    });
  },
});

const { actions, reducer } = agentsSlice;
export const { resetUpdatingState, setAgentFilter, setStatusFilter, resetFilters } = actions;
export default reducer;
