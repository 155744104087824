import React from "react";
import { AgentStatus, AgentStatusOption } from "../../api/agent";
import { Typography } from "@material-ui/core";
import format from "date-fns/format";
import { fi } from "date-fns/locale";

interface Props {
  status?: AgentStatusOption;
  updated?: Date;
  showDate?: boolean;
}
export const AgentStatusValue = ({ status, updated, showDate = false }: Props): JSX.Element | null => {
  if (!status) return null;
  if (status.value === AgentStatus.BLOCKED) {
    return (
      <Typography variant="body2" color="error" component="span">
        {status.name} {showDate && updated ? `(${format(updated, "P", { locale: fi })})` : ""}
      </Typography>
    );
  }
  if (status.value === AgentStatus.WARNING) {
    return (
      <Typography variant="body2" style={{ color: "#c80" }} component="span">
        {status.name} {showDate && updated ? `(${format(updated, "P", { locale: fi })})` : ""}
      </Typography>
    );
  }
  return (
    <Typography variant="body2" component="span">
      {status.name}
    </Typography>
  );
};
