import useSWR from "swr";
import intersection from "lodash/intersection";
import { authFetchPost } from "../utils/fetch";
import { GRAPH_API_URL, WISH_ADMIN_GROUPS, AGENT_ADMIN_GROUPS } from "../config";
import { UserGroup } from "../api/user";
import { IPublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";

const authFetchPostWithBody = async (url: string, authProvider: IPublicClientApplication) =>
  authFetchPost<GraphApiResponse>(url, authProvider, {
    body: JSON.stringify({
      groupIds: [...WISH_ADMIN_GROUPS, ...AGENT_ADMIN_GROUPS],
    }),
  });

interface GraphApiResponse {
  value: string[];
}
export function useUserGroups(): { userGroups: UserGroup[]; error: boolean; loading: boolean } {
  const { instance: publicClientApplication } = useMsal();
  const url = `${GRAPH_API_URL}/me/checkMemberGroups`;
  const { data, error } = useSWR<GraphApiResponse>(url, () => authFetchPostWithBody(url, publicClientApplication));

  const userGroups = [];
  if (data?.value && intersection(data?.value, WISH_ADMIN_GROUPS).length > 0) {
    userGroups.push(UserGroup.WISH_ADMIN);
  }
  if (data?.value && intersection(data?.value, AGENT_ADMIN_GROUPS).length > 0) {
    userGroups.push(UserGroup.AGENT_ADMIN);
  }

  return { userGroups, error, loading: !error && !data };
}
