import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { LoginRequired } from "./pages/LoginRequired";
import Header from "./components/common/Header";
import { Footer } from "./components/common/Footer";
import Main from "./Main";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { LeftDrawerMenu } from "./components/common/LeftDrawerMenu";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(4, 2),
    },
  }),
);

function handleLogin(loginInstance: IPublicClientApplication) {
  loginInstance.loginRedirect(loginRequest).catch((e) => {
    console.error(e);
  });
}

function handleLogout(loginInstance: IPublicClientApplication) {
  loginInstance.logoutRedirect().catch((e) => {
    console.error(e);
  });
}

function App(): JSX.Element {
  const { instance: publicClientApplication } = useMsal();

  if (!publicClientApplication) {
    throw Error("No pca! Verify configuration.");
  }

  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawerOpen = (open?: boolean) => {
    setDrawerOpen(open || !drawerOpen);
  };

  const activeAccount = publicClientApplication.getActiveAccount();

  return (
    <Router>
      <Header
        loggedUser={activeAccount?.name}
        login={() => handleLogin(publicClientApplication)}
        logout={() => handleLogout(publicClientApplication)}
        title="Toivehallinta"
        drawerOpen={drawerOpen}
        toggleDrawerOpen={toggleDrawerOpen}
      />
      <div className={classes.root}>
        <AuthenticatedTemplate>
          <LeftDrawerMenu open={drawerOpen} onClose={() => toggleDrawerOpen(false)} />
        </AuthenticatedTemplate>

        <main className={classes.content}>
          <div className={classes.toolbar} />
          <AuthenticatedTemplate>
            <Main activeAccount={activeAccount} />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <LoginRequired />
          </UnauthenticatedTemplate>
        </main>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
