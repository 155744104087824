import React, { useState } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { Select } from "../common/Select";
import { statuses } from "../../api/wish";

interface Props {
  selected: string[];
  handleUpdateMultiple: (status: string) => void;
}
export const MultiRowActions = ({ selected, handleUpdateMultiple }: Props): JSX.Element => {
  const [statusForSelectedRows, setStatusForSelectedRows] = useState<string>("");

  return (
    <Grid container direction="row" alignItems="center" spacing={4}>
      <Grid item>
        <Typography color="primary" variant="subtitle1" component="div" style={{ fontWeight: "bold" }}>
          {selected.length} valittu
        </Typography>
      </Grid>
      <Grid item>
        <Grid container direction="row" spacing={2} alignItems="center">
          <Grid item>
            <Typography color="primary" variant="subtitle1" component="span">
              Aseta valituille:
            </Typography>
          </Grid>
          <Grid item>
            <Select
              id="statusForSelectedRows"
              name="statusForSelectedRows"
              label="Tila"
              variant="outlined"
              options={statuses.filter((s) => s.userSettable)}
              value={statusForSelectedRows}
              onChange={(event: React.ChangeEvent<{ value: string | number }>) => {
                setStatusForSelectedRows(`${event.target.value}`);
              }}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleUpdateMultiple(statusForSelectedRows)}
              startIcon={<CheckCircleOutlineIcon />}
            >
              Tallenna
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
