import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import intersectionWith from "lodash/intersectionWith";
import { statuses, WishStatusOption } from "../../api/wish";

interface Props {
  value?: WishStatusOption[];
  onChange: (status: WishStatusOption[] | null) => void;
  size?: "small" | "medium";
  userSettableOptionsOnly?: boolean;
}
export const WishStatusFilter = ({
  value,
  onChange,
  size = "medium",
  userSettableOptionsOnly = false,
}: Props): JSX.Element => {
  return (
    <Autocomplete
      multiple
      options={statuses.filter((s) => !userSettableOptionsOnly || s.userSettable)}
      filterSelectedOptions
      getOptionSelected={(option: WishStatusOption, value: WishStatusOption) =>
        option.value.toString() === value.value.toString()
      }
      getOptionLabel={(option: WishStatusOption) => option.name}
      onChange={(_: unknown, value: WishStatusOption[] | null) => onChange(value)}
      renderInput={(params) => <TextField {...params} variant="outlined" label="Tila" style={{ minWidth: 150 }} />}
      value={value ? intersectionWith(statuses, value, (a, b) => a.value === b.value) : []}
      size={size}
    />
  );
};
