import useSWR from "swr";
import { getApiUrl } from "../utils/request";
import { Agent } from "../api/agent";
import fetch from "../utils/fetch";
import { ApiResponse } from "../utils/response";

const collator = new Intl.Collator(["fi", "sv"]);

export function useAllAgents(): { agents: Agent[]; isLoading: boolean; isError: boolean } {
  const { data, error } = useSWR<ApiResponse<Agent[]>>(getApiUrl("/v1/agents"), fetch);

  const agents = data ? data.data : [];
  if (agents.length) {
    agents.sort((a: Agent, b: Agent) => collator.compare(a.name, b.name));
  }

  return {
    agents: agents,
    isLoading: !error && !data,
    isError: error,
  };
}
