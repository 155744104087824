import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useAllAgents } from "../../hooks/useAllAgents";
import { Agent } from "../../api/agent";

interface Props {
  value?: string;
  onChange: (agentId: string | null) => void;
  size?: "small" | "medium";
}
export const AgentFilter = ({ value, onChange, size = "medium" }: Props): JSX.Element => {
  const { agents, isLoading } = useAllAgents();

  return (
    <Autocomplete
      options={agents}
      getOptionLabel={(agent: Agent) => `${agent.agentId} ${agent.name}`}
      renderInput={(params) => <TextField {...params} label="Toimittaja" variant="outlined" />}
      value={agents.find((agent: Agent) => agent.agentId === value) || null}
      style={{ width: 300 }}
      autoHighlight
      onChange={(_: unknown, value: Agent | null) => onChange(value?.agentId || null)}
      size={size}
      loading={isLoading}
      loadingText="Ladataan..."
    />
  );
};
