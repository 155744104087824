import React from "react";
import { Box, Typography } from "@material-ui/core";
import { BackButton } from "../components/common/BackButton";

export const NotFound = (): JSX.Element => {
  return (
    <Box alignSelf="center" textAlign="center">
      <Typography variant="h3">Hupsista! Sivua ei löytynyt.</Typography>
      <Box py={4} alignSelf="center" textAlign="center">
        <BackButton text="Takaisin etusivulle" icon="arrow" returnTo={"/"} />
      </Box>
    </Box>
  );
};
