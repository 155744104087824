import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { WishTablePage } from "../components/wishes/WishTablePage";
import { HeadCell } from "../components/common/table/TableHead";
import { OrderDirection } from "../utils/order";

const headCells: HeadCell[] = [
  { id: "created", numeric: true, sortable: true, disablePadding: false, label: "PVM" },
  { id: "product.id", numeric: true, sortable: true, disablePadding: false, label: "Tuote" },
  { id: "product.name", numeric: false, sortable: true, disablePadding: false, label: "Tuotenimi" },
  { id: "product.mainGroupName[0]", numeric: false, sortable: true, disablePadding: false, label: "Tuoteryhmä" },
  { id: "product.segment", numeric: false, sortable: false, disablePadding: false, label: "Segmentti" },
  {
    id: "product.presentationGroup.name",
    numeric: false,
    sortable: false,
    disablePadding: false,
    label: "Esillep.ryhmä",
  },
  { id: "product.basket", numeric: false, sortable: false, disablePadding: false, label: "Kori" },
  { id: "product.price", numeric: true, sortable: false, disablePadding: false, label: "Hinta" },
  { id: "status", numeric: false, sortable: false, disablePadding: false, label: "Tila" },
  { id: "comment", numeric: false, sortable: false, disablePadding: false, label: "Kommentti" },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
  }),
);

export const ListWishes = ({ loggedUserName }: { loggedUserName?: string }): JSX.Element => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderDirection, setOrderDirection] = useState<OrderDirection>("desc");
  const [orderBy, setOrderBy] = useState<string>("created");

  const [editRow, setEditRow] = useState("");

  const { storeId, wishFilters } = useLocalStorage();

  const createdDateFilterStart = wishFilters?.createdStart || null;
  const createdDateFilterEnd = wishFilters?.createdEnd || null;
  const storeIdFilter = storeId || undefined;
  const agentIdFilter = wishFilters?.agentId;
  const productIdFilter = wishFilters?.productId;
  const productGroupIdFilter = wishFilters?.productGroup;
  const statusFilter = wishFilters?.status?.map((s) => s.value);

  const queryFilters = {
    "created.start": createdDateFilterStart,
    "created.end": createdDateFilterEnd,
    "store.id": storeIdFilter,
    "product.agentId": agentIdFilter,
    "product.id": productIdFilter,
    "product.productGroupId": productGroupIdFilter,
    status: statusFilter,
  };

  const statusFilterDependency = JSON.stringify(statusFilter);
  useEffect(() => {
    // reset page if filters change
    setPage(0);
  }, [
    createdDateFilterStart,
    createdDateFilterEnd,
    storeIdFilter,
    agentIdFilter,
    productIdFilter,
    productGroupIdFilter,
    statusFilterDependency,
  ]);

  return (
    <div className={classes.root}>
      <WishTablePage
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        orderDirection={orderDirection}
        setOrderDirection={setOrderDirection}
        filters={queryFilters}
        headCells={headCells}
        editRow={editRow}
        setEditRow={setEditRow}
        loggedUserName={loggedUserName}
      />
      <WishTablePage
        page={page + 1}
        rowsPerPage={rowsPerPage}
        orderBy={orderBy}
        orderDirection={orderDirection}
        filters={queryFilters}
        headCells={headCells}
        loggedUserName={loggedUserName}
        hidden
      />
    </div>
  );
};
