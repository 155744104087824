import React from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

interface Props {
  text?: string | boolean;
  icon?: "arrow" | "chevron";
  returnTo: string;
}

const getIcon = (icon: string) => (icon === "arrow" ? <ArrowBackIcon /> : <ChevronLeftIcon />);

export const BackButton = ({ text = "Takaisin", icon = "chevron", returnTo }: Props): JSX.Element => {
  if (!text) {
    return (
      <IconButton href={returnTo} size="medium" title="Takaisin">
        {getIcon(icon)}
      </IconButton>
    );
  }
  return (
    <Button href={returnTo} size="large" startIcon={getIcon(icon)}>
      {text}
    </Button>
  );
};
