import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid, Typography, Button } from "@material-ui/core";
import BlockIcon from "@material-ui/icons/Block";
import WarningIcon from "@material-ui/icons/ReportProblemOutlined";
import { useHistory } from "react-router-dom";
import { AgentStatus } from "../../api/agent";
import { resetFilters, setAgentFilter, setStatusFilter } from "../../state/agents";
import { RootState, useAppDispatch } from "../../reduxStore";
import { AgentFilter } from "../filter/AgentFilter";
import { useLocalStorage } from "../../hooks/useLocalStorage";

export const AgentFilterToolbar = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const filters = useSelector((state: RootState) => state.agents.filters);
  const history = useHistory();
  const { agentFilters, setAgentFilters } = useLocalStorage();

  const saveStatusFilter = (value: AgentStatus) => {
    const oldValue = agentFilters?.status;
    setAgentFilters({ ...agentFilters, status: value !== oldValue ? value : undefined });
  };

  const saveAgentFilter = (value: string | null) => {
    setAgentFilters({ ...agentFilters, agentId: value || undefined });
  };

  useEffect(() => {
    if (agentFilters?.status && agentFilters.status !== filters.status) {
      dispatch(setStatusFilter(agentFilters.status));
    }
    if (agentFilters?.agentId) {
      dispatch(setAgentFilter(agentFilters.agentId));
    }
  }, [agentFilters, dispatch, filters.status]);

  return (
    <Grid container direction="row" alignItems="center">
      <Grid item>
        <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid item>
            <Typography color="inherit" variant="subtitle1" component="span">
              Suodatus:
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant={filters?.status === AgentStatus.WARNING ? "contained" : "outlined"}
              color="primary"
              onClick={() => {
                saveStatusFilter(AgentStatus.WARNING);
                dispatch(setStatusFilter(AgentStatus.WARNING));
              }}
              startIcon={<WarningIcon />}
            >
              Varoitukset
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant={filters?.status === AgentStatus.BLOCKED ? "contained" : "outlined"}
              color="secondary"
              onClick={() => {
                saveStatusFilter(AgentStatus.BLOCKED);
                dispatch(setStatusFilter(AgentStatus.BLOCKED));
              }}
              startIcon={<BlockIcon />}
            >
              Estetyt
            </Button>
          </Grid>
          <Grid item>
            <AgentFilter
              value={filters.agentId}
              onChange={(agentId) => {
                saveAgentFilter(agentId);
                dispatch(setAgentFilter(agentId));
              }}
              size="small"
            />
          </Grid>
          <Grid item>
            <Button
              variant="text"
              onClick={() => {
                setAgentFilters(null);
                dispatch(resetFilters());
                history.push("/agents");
              }}
            >
              Tyhjennä
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
