import React from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import { makeStyles, Theme, createStyles, Drawer, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import ChatIcon from "@material-ui/icons/Chat";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import { UserGroup } from "../../api/user";
import { useUserGroups } from "../../hooks/useUserGroups";

interface ListItemLinkProps {
  icon?: React.ReactElement;
  primary: string;
  to: string;
  onClick?: () => void;
}

function ListItemLink(props: ListItemLinkProps) {
  const { icon, primary, to, onClick } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, "to">>((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to],
  );

  return (
    <li>
      <ListItem button component={renderLink} onClick={onClick}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

const drawerWidth = 220;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerContainer: {
      overflow: "auto",
      paddingTop: theme.spacing(2) + 80,
      overflowX: "hidden",
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(4, 2),
    },
  }),
);

interface Props {
  open: boolean;
  onClose: () => void;
}
export const LeftDrawerMenu = ({ open, onClose }: Props): JSX.Element => {
  const { userGroups } = useUserGroups();
  const classes = useStyles();
  return (
    <div>
      <Drawer
        anchor="left"
        variant="temporary"
        open={open}
        onClose={onClose}
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerContainer}>
          <List>
            {userGroups?.includes(UserGroup.WISH_ADMIN) && (
              <ListItemLink to="/wishes" primary="Toiveet" icon={<InboxIcon />} onClick={onClose} />
            )}
            {userGroups?.includes(UserGroup.WISH_ADMIN) && (
              <ListItemLink to="/open-wishes" primary="Avoimet toiveet" icon={<ChatIcon />} onClick={onClose} />
            )}
            {userGroups?.includes(UserGroup.AGENT_ADMIN) && (
              <ListItemLink to="/agents" primary="Toimittajat" icon={<LocalShippingIcon />} onClick={onClose} />
            )}
          </List>
        </div>
      </Drawer>
    </div>
  );
};
