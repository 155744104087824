import React from "react";
import { Route, Switch } from "react-router-dom";
import { NotFound } from "./pages/NotFound";
import { Unauthorized } from "./pages/Unauthorized";
import { Loading } from "./pages/Loading";
import { ListWishes } from "./pages/ListWishes";
import { ListAgents } from "./pages/ListAgents";
import { UserGroup } from "./api/user";
import { useUserGroups } from "./hooks/useUserGroups";
import { ListOpenWishes } from "./pages/ListOpenWishes";
import { AccountInfo } from "@azure/msal-browser";

interface MainProps {
  activeAccount: AccountInfo | null;
}

function Main({ activeAccount }: MainProps): JSX.Element {
  const { userGroups, loading: isUserGroupsLoading } = useUserGroups();
  const hasValidUserGroups = userGroups && userGroups.length > 0;

  if (isUserGroupsLoading || !activeAccount?.name) {
    return <Loading />;
  } else if (hasValidUserGroups) {
    const userName = activeAccount.username;
    return (
      <Switch>
        {userGroups?.includes(UserGroup.AGENT_ADMIN) && (
          <Route path="/agents" key="route-add">
            <ListAgents loggedUserName={userName} />
          </Route>
        )}
        {userGroups?.includes(UserGroup.WISH_ADMIN) && (
          <Route path="/wishes" key="route-add">
            <ListWishes loggedUserName={userName} />
          </Route>
        )}
        {userGroups?.includes(UserGroup.WISH_ADMIN) && (
          <Route path="/open-wishes" key="route-add">
            <ListOpenWishes loggedUserName={userName} />
          </Route>
        )}
        {userGroups?.includes(UserGroup.WISH_ADMIN) && (
          <Route exact path="/">
            <ListWishes loggedUserName={userName} />
          </Route>
        )}
        <Route path="*" component={hasValidUserGroups ? NotFound : Unauthorized} />
      </Switch>
    );
  } else {
    return <Unauthorized />;
  }
}

export default Main;
