import { ApiResponse } from "../utils/response";
import { getFromApi, putToApi } from "../utils/request";

export interface WishFilter {
  "created.start"?: string | null;
  "created.end"?: string | null;
  "store.id"?: string;
  "product.agentId"?: string;
  "product.productGroupId"?: string;
  status?: WishStatusOption[];
}

export enum WishStatus {
  NEW = "NEW",
  ACTIVATED = "ACTIVATED",
  PLANNING = "PLANNING",
  IN_SHELF = "IN_SHELF",
  OUT_OF_STOCK = "OUT_OF_STOCK",
  REMOVED = "REMOVED",
  OLD_PRODUCT = "OLD_PRODUCT",
  REJECTED = "REJECTED",
  WARNING = "WARNING",
  BLOCKED = "BLOCKED",
}

interface EmptyLocation {
  city: null;
  region: null;
  regionCode: null;
  country: null;
  countryName: null;
  postal: null;
}
interface Location {
  city: string;
  region: string;
  regionCode: string;
  country: string;
  countryName: string;
  postal: string;
}

interface WishBase {
  id: string;
  wishNumber: number;
  status: WishStatus;
  comment: string | null;
  created: Date;
  createdBy: string;
  updated: Date | null;
  updatedBy: string | null;
  deleted: Date | null;
  deletedBy: string | null;
  contactInformation?: {
    name: string;
    email?: string;
    phone?: string;
  };
  location?: Location | EmptyLocation;
}

export interface Wish extends WishBase {
  store: {
    id: string;
    name: string;
  };
  product: {
    id: string;
    name: string;
    mainGroupId: string[];
    mainGroupName: string[];
    productGroupId: string[];
    productGroupName: string[];
    basket: string;
    presentationGroup: {
      name: string;
    };
    segment: string;
    price: number;
    agentId: number;
    agentName: string;
  };
  inShelfDate?: Date | null;
  outOfStockDate?: Date | null;
  removedDate?: Date | null;
}

export interface OpenWish extends WishBase {
  store?: {
    id: string;
    name: string;
  };
  openWish: {
    country?: string;
    productGroup: {
      id: string;
      name: {
        [key: string]: string;
      };
      productGroupIds: string[];
    };
    wishText: string;
  };
}

export interface WishStatusOption {
  name: string;
  value: WishStatus;
  userSettable: boolean;
}
export const statuses: WishStatusOption[] = [
  { value: WishStatus.NEW, name: "Uusi", userSettable: true },
  { value: WishStatus.PLANNING, name: "Harkinnassa", userSettable: true },
  { value: WishStatus.ACTIVATED, name: "Aktivoitu", userSettable: true },
  { value: WishStatus.IN_SHELF, name: "Hyllyssä", userSettable: true },
  { value: WishStatus.OUT_OF_STOCK, name: "Loppunut", userSettable: false },
  { value: WishStatus.REMOVED, name: "Poistunut", userSettable: false },
  { value: WishStatus.OLD_PRODUCT, name: "Vanha tuote", userSettable: false },
  { value: WishStatus.REJECTED, name: "Ei toteudu", userSettable: true },
  { value: WishStatus.WARNING, name: "Hälytys", userSettable: true },
  { value: WishStatus.BLOCKED, name: "Estetty", userSettable: true },
];

export interface FindWishesParams {
  page: number;
  pageSize: number;
  orderBy?: string;
  orderDirection?: string;
  filters?: WishFilter;
}

export const findWishes = async (params: FindWishesParams): Promise<ApiResponse<Wish[]>> => {
  return await getFromApi<Wish[]>("/v1/wishes", params);
};

export const findOpenWishes = async (params: FindWishesParams): Promise<ApiResponse<OpenWish[]>> => {
  return await getFromApi<OpenWish[]>("/v1/wishes/open", params);
};

export interface UpdateWishParams {
  wishId: string;
  status?: WishStatus;
  comment?: string;
  updatedBy?: string;
}

export const updateWish = async (params: UpdateWishParams): Promise<ApiResponse<Wish>> => {
  return await putToApi<Wish>(`/v1/wishes/${params.wishId}`, params);
};

export const updateWishes = async (params: UpdateWishParams[]): Promise<ApiResponse<Wish[]>> => {
  return await putToApi<Wish[]>(`/v1/wishes`, params);
};

export const updateOpenWishes = async (params: UpdateWishParams[]): Promise<ApiResponse<OpenWish[]>> => {
  return await putToApi<OpenWish[]>(`/v1/wishes`, params);
};
