import { Wish } from "../api/wish";
import { postFormToUrl } from "./postFormToUrl";

const getSelectedProductIds = (wishes: Wish[], selectedWishIds: string[]) => {
  const selectedProductIds = wishes.filter((w) => selectedWishIds.includes(w.id)).map((wish) => wish.product.id);

  return Array.from(new Set(selectedProductIds));
};

export const printProducts = (wishes: Wish[], selectedWishIds: string[]): void => {
  const printSelected = getSelectedProductIds(wishes, selectedWishIds);

  if (printSelected.length === 0) {
    return;
  }
  if (printSelected.length >= 100) {
    return;
  }

  const payload: Record<string, string[]> = {};
  payload["productnumber"] = printSelected;

  postFormToUrl(process.env.REACT_APP_PRINTING_URL, payload);
};
