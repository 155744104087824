import React from "react";
import { FormControl, MenuItem, makeStyles, Theme, createStyles, TextField, TextFieldProps } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 120,
    },
  }),
);

interface Option {
  name: string;
  value: string;
}

interface Props<T extends Option> {
  id: string;
  name: string;
  label: string;
  options?: T[];
  inputRef?: React.Ref<HTMLInputElement>;
  onChange?: (event: React.ChangeEvent<Option>) => void;
  variant?: "standard" | "filled" | "outlined";
  optionForAll?: boolean;
}
export const Select = <T extends Option>({
  id,
  name,
  label,
  options,
  inputRef,
  onChange,
  variant = "standard",
  optionForAll = false,
  ...rest
}: Props<T> & TextFieldProps): JSX.Element => {
  const classes = useStyles();
  return (
    <FormControl className={classes.formControl}>
      <TextField
        id={id}
        name={name}
        label={label}
        onChange={onChange}
        select
        variant={variant}
        inputRef={inputRef}
        {...rest}
      >
        {optionForAll && (
          <MenuItem key={`${id}-all`} value={""}>
            <em>Kaikki</em>
          </MenuItem>
        )}
        {options?.map(({ name, value }: T) => (
          <MenuItem key={`${id}-${name}-${value}`} value={value}>
            {name}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
};
