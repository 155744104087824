import React from "react";
import { useHistory } from "react-router-dom";
import { Grid, Typography, Button } from "@material-ui/core";
import { WishStatusOption } from "../../api/wish";
import { WishStatusFilter } from "../filter/WishStatusFilter";
import { DateRangeFilter } from "../filter/DateRangeFilter";
import { useLocalStorage } from "../../hooks/useLocalStorage";

export const OpenWishFilterToolbar = (): JSX.Element => {
  const history = useHistory();
  const { wishFilters, setWishFilters } = useLocalStorage();
  const filterSize = "small";

  const setDateRangeStartFilter = (start: string | null) => {
    setWishFilters({ ...wishFilters, createdStart: start || undefined });
  };
  const setDateRangeEndFilter = (end: string | null) => {
    setWishFilters({ ...wishFilters, createdEnd: end || undefined });
  };

  const saveStatusFilter = (value: WishStatusOption[] | null) => {
    setWishFilters({ ...wishFilters, status: value || undefined });
  };

  const clearFilters = () => {
    setWishFilters(null);
    history.push("/open-wishes");
  };

  return (
    <Grid container direction="row" alignItems="center" spacing={2}>
      <Grid item>
        <Typography color="inherit" variant="subtitle1" component="span">
          Suodatus:
        </Typography>
      </Grid>
      <Grid item>
        <Grid container direction="row" alignItems="center" justify="flex-start" spacing={2}>
          <Grid item>
            <DateRangeFilter
              startValue={wishFilters?.createdStart || null}
              endValue={wishFilters?.createdEnd || null}
              onChangeStart={setDateRangeStartFilter}
              onChangeEnd={setDateRangeEndFilter}
              size={filterSize}
            />
          </Grid>
          <Grid item>
            <WishStatusFilter
              value={wishFilters?.status}
              onChange={(value: WishStatusOption[] | null) => {
                saveStatusFilter(value);
              }}
              userSettableOptionsOnly={false}
              size={filterSize}
            />
          </Grid>
          <Grid item>
            <Button variant="text" onClick={() => clearFilters()} size={filterSize}>
              Tyhjennä
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
